<template>
  <div>
    <tab-bar-wrapper @newRow="showAddApInvoiceSheet"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :query="query"
        :nested-query="nestedQuery"
        :filter="filters"
        :nested-filters="nestedFilters"
        :columns="columns"
        :contextMenuItems="contextMenuItems"
        :nested-columns="nestedColumns"
        :nested-context-menu-items="nestedContextMenuItems"
        :nested-grid="true"
        :componentName="$options.name"
        @ready="gridApi = $event.api"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <v-dialog
      v-model="dialog.show"
      lazy
      persistent
      max-width="700px"
    >
      <component v-bind="dialog.props" :is="dialog.component" @close="resetDialog" @refetch="refetch"/>
    </v-dialog>
    <v-bottom-sheet
      v-model="sheet"
      lazy
      persistent
    >
      <new-invoice v-if="sheet" type="AP" @close="sheet = false" @success="refresh"/>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__ACCOUNTING_AP, KB_SHORTCUT__ACCOUNTING_AP_NESTED } from '@/lib/agGridKbShortCuts'
import { CONTEXT_MENU__ACCOUNTING_AP, CONTEXT_MENU__ACCOUNTING_AP_NESTED } from '@/lib/agGridContextMenuConfigurations'
import { COLUMNS__ACCOUNTING_AP, COLUMNS__ACCOUNTING_AP_NESTED } from '@/lib/agGridColumnConfiguration'
import {
  GET_ACCOUNTING_AP_INVOICES__FULL,
  GET_INVOICE_PAYMENTS_FOR_AP_INVOICE__FULL
} from '@/api/graphql/Constants/Accounting'
import NewInvoice from '@/components/forms/NewInvoice'
import AddPaymentToInvoice from '@/components/dialogs/AddPaymentToInvoice'
import VoidInvoices from '@/components/dialogs/VoidInvoices'

export default {
  name: 'Payable',
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'grid-wrapper': GridWrapper,
    'new-invoice': NewInvoice,
    'add-payment-to-invoice': AddPaymentToInvoice,
    'void-invoices': VoidInvoices
  },
  data () {
    return {
      query: GET_ACCOUNTING_AP_INVOICES__FULL,
      nestedQuery: GET_INVOICE_PAYMENTS_FOR_AP_INVOICE__FULL,
      filters: [],
      nestedFilters: (id) => [{ key: 'invoice__id', value: id }],

      contextMenuItems: (params) => CONTEXT_MENU__ACCOUNTING_AP(params, this.contextMenuCallback),

      nestedContextMenuItems: params => CONTEXT_MENU__ACCOUNTING_AP_NESTED(params, this.contextMenuCallback),

      columns: COLUMNS__ACCOUNTING_AP,
      nestedColumns: COLUMNS__ACCOUNTING_AP_NESTED,

      dialog: { show: false, component: '' },
      sheet: false,

      // dialog stuff
      invoicesToVoid: [],

      // grid api's
      gridApi: null,
      nestedGridApi: null
    }
  },
  methods: {
    contextMenuCallback ({ functionToRun, params, data, dataKey }) {
      if (dataKey) this[dataKey] = data
      if (functionToRun) this[functionToRun](params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__ACCOUNTING_AP(params, this.contextMenuCallback), KB_SHORTCUT__ACCOUNTING_AP_NESTED(params, this.contextMenuCallback))
    },

    resetDialog () {
      this.dialog.component = ''
      this.dialog.show = false
      this.dialog.props = {}
    },

    showAddApInvoiceSheet () {
      this.sheet = true
    },

    refetch () {
      if (this.nestedGridApi) {
        this.nestedGridApi.purgeServerSideCache()
        this.nestedGridApi = null
      }
    },

    refresh () {
      this.$store.dispatch('data/changeRefresh', { bool: true })
    },

    showAddPaymentToInvoiceDialog ({ params }) {
      // save nested grid api for later refreshing
      this.nestedGridApi = params.api.getDetailGridInfo(`detail_${params.node.id}`)?.api

      // prepare payment adding
      const id = params.node.data.id
      Vue.set(this.dialog, 'props', { invoiceId: id.toString(), invoiceType: 'AP' })
      this.dialog.component = 'add-payment-to-invoice'
      this.dialog.show = true
    },

    prepareToVoidApInvoices ({ params }) {
      const invoices = []
      for (const node of params.api.getSelectedNodes()) {
        invoices.push(node.data)
      }
      this.dialog.show = true
      this.dialog.component = 'void-invoices'
      Vue.set(this.dialog, 'props', { invoices: invoices, type: 'AP' })
    }
  }
}
</script>
